import { defineStore } from 'pinia'
import { ref } from 'vue'

import { useApiClient } from '@/api'
import dayjs from '@/dayjs'
import {
  CancelablePromise,
  FanVestorDetail,
  type NotificationModel,
  PasswordChangeRequest,
  PasswordResetRequest,
  PatchedClubUserAccountDetailsUpdateModelRequest,
  PatchedClubUserSystemSettingsUpdateModelRequest,
  PatchedFanVestorAccountDetailsUpdateModelRequest,
  PatchedFanVestorSystemSettingsUpdateModelRequest,
  PhoneNumber,
  RegisterFanVestor,
  RegisterFanVestorRequest,
  type User,
} from '@/generated/api'
import i18n from '@/i18n'

export const useAuthStore = defineStore('auth', () => {
  const apiClient = useApiClient()
  const user = ref(null as User | null)

  const notificationsList = ref(null as unknown as Array<NotificationModel>)

  async function fetchNotifications() {
    notificationsList.value = await apiClient.notifications.notificationsList()
    return notificationsList.value
  }

  async function acknowledgeNotification(notificationPk: string) {
    const result = apiClient.notifications.notificationsAckCreate({ notificationPk })
    await fetchNotifications()
    return result
  }

  async function fetchMe(force?: boolean): Promise<User | null> {
    if (user.value && !force) {
      return user.value
    }

    try {
      user.value = await apiClient.users.usersMeRetrieve()
      i18n.global.locale.value = user.value?.language ?? i18n.global.locale.value
      dayjs.locale(i18n.global.locale.value)
      localStorage.setItem('lang', i18n.global.locale.value)
      return user.value
    } catch (e) {
      return null
    }
  }

  async function updateFanvestorSystemSettings(
    requestBody: PatchedFanVestorSystemSettingsUpdateModelRequest
  ): Promise<FanVestorDetail | null> {
    user.value = await apiClient.users.usersFanvestorsSystemSettingsPartialUpdate({
      requestBody,
    })
    return user.value
  }

  async function updateClubUserSystemSettings(
    requestBody: PatchedClubUserSystemSettingsUpdateModelRequest
  ) {
    user.value = await apiClient.users.usersClubUsersSystemSettingsPartialUpdate({
      requestBody,
    })
    return user.value
  }

  async function updateFanvestorProfileDetails(
    data: PatchedFanVestorAccountDetailsUpdateModelRequest
  ) {
    return apiClient.users.usersFanvestorsAccountDetailsPartialUpdate({ requestBody: data })
  }

  async function updateClubUserProfileDetails(
    data: PatchedClubUserAccountDetailsUpdateModelRequest
  ) {
    return apiClient.users.usersClubUsersAccountDetailsPartialUpdate({ requestBody: data })
  }

  async function registerFanvestor(
    requestBody: RegisterFanVestorRequest
  ): Promise<RegisterFanVestor> {
    return apiClient.users.usersFanvestorsRegisterCreate({
      requestBody,
    })
  }

  async function verifyEmail(code: string): Promise<void> {
    return apiClient.users
      .usersEmailVerificationCreate({
        requestBody: { code },
      })
      .then(() => {})
  }

  async function login(email: string, password: string, two_fa_code: string | null = null) {
    logout()
    const result = (await apiClient.token.tokenCreate({
      requestBody: { email, password, two_fa_code },
    })) as unknown as any

    const phoneNumber = result.phone_number
    if (phoneNumber) {
      return phoneNumber
    }
    return await fetchMe()
  }

  function logout() {
    apiClient.token.tokenBlacklistCreate({
      requestBody: { refresh: '' },
    })
    user.value = null
  }

  async function sendPasswordResetEmail(requestBody: PasswordResetRequest): Promise<void> {
    return apiClient.users.usersPasswordResetCreate({
      requestBody,
    })
  }

  async function requestPasswordReset(email: string): Promise<void> {
    return apiClient.users.usersRequestPasswordResetCreate({
      requestBody: { email },
    })
  }

  async function changePassword(requestBody: PasswordChangeRequest): Promise<void> {
    return apiClient.users.usersPasswordChangeCreate({
      requestBody,
    })
  }

  async function sendPhoneNumberVerificationCode(
    phone_number: string
  ): Promise<CancelablePromise<PhoneNumber>> {
    return apiClient.users.usersSendPhoneNumberVerificationCodeCreate({
      requestBody: { phone_number },
    })
  }

  async function enable2FaViaSms(phone_number: string, code: string): Promise<User | null> {
    return apiClient.users.usersEnable2FaViaSmsCreate({
      requestBody: { phone_number, code },
    })
  }

  async function disable2FaViaSms(): Promise<User | null> {
    return apiClient.users.usersDisable2FaViaSmsCreate()
  }

  async function getTermsOfUseDocumentUrl() {
    return apiClient.users.usersTermsOfUseRetrieve()
  }

  async function acceptTermsOfUse() {
    await apiClient.users.usersTermsOfUseAcceptCreate()
    await fetchMe(true)
  }

  async function getReferralCount() {
    return apiClient.users.usersReferralCountRetrieve()
  }

  async function claimReferralBonus() {
    return apiClient.users.usersReferralClaimBonusCreate()
  }

  return {
    user,
    notificationsList,
    fetchNotifications,
    acknowledgeNotification,
    fetchMe,
    login,
    logout,
    registerFanvestor,
    verifyEmail,
    sendPasswordResetEmail,
    requestPasswordReset,
    updateFanvestorSystemSettings,
    updateFanvestorProfileDetails,
    changePassword,
    updateClubUserProfileDetails,
    updateClubUserSystemSettings,
    sendPhoneNumberVerificationCode,
    enable2FaViaSms,
    disable2FaViaSms,
    getTermsOfUseDocumentUrl,
    acceptTermsOfUse,
    getReferralCount,
    claimReferralBonus,
  }
})
